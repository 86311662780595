import axios from 'axios';
import getJWT from './token';
import Papa from 'papaparse';

let baseApi = `https://api.${process.env.REACT_APP_BASE_URL || "mattk.commerce-ims.com"}`;

function formatFile(file) {
    return new Promise((resolve, reject) => {
        if(path.extname(file.name) === ".csv") {
            Papa.parse(file, {worker: true, header: true, complete: function(csv) {
                resolve(csv.data);
            }})
        }
        else {
            console.log("Failed to format file.");
            reject();
        }
    });
}
var path = require('path');

const bulkHelpers = {
    downloadTemplate: async function(company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/labels/template"
        axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((result) => {
            let buffer = Buffer.from(result.data.Body);
            let blob = new Blob([buffer], {type: "text/csv"})
            const a = document.createElement("a");
            a.download = "BoxLabelTemplate.csv";
            a.href = window.URL.createObjectURL(blob);
            a.click();
        })
        .catch(function(err) {
            console.log(err);
        })
    },

    downloadList: async function(setFiles, company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/labels"
        axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((result) => {
            let data = result.data.Contents.map(function(obj, index) {return {key: index, name: obj.Key, date: obj.LastModified}}).filter(obj => obj.name.indexOf("TEMP") === -1)
            data.sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime());
            setFiles(data);
        })
        .catch(function(err) {
            console.log(err);
        })
    },

    downloadPalletList: async function(setFiles, company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/pallet_labels"
        axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((result) => {
            let data = result.data.Contents.map(function(obj, index) {return {key: index, name: obj.Key, date: obj.LastModified}}).filter(obj => obj.name.indexOf("TEMP") === -1)
            data.sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime());
            setFiles(data);
        })
        .catch(function(err) {
            console.log(err);
        })
    },

    regenerateBoxLabels: async function(internal_po_number, order_num, vendor_name, warehouse, company_id, token) {
        const userToken = await getJWT(token);
        await axios.post(baseApi + '/bulk/labels/regenerate', {internal_po_number: internal_po_number, order_num: order_num, warehouse: warehouse, vendor_name: vendor_name}, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
    },

    generatePalletLabels: async function(internal_po_number, vendor_name, company_id, token) {
        const userToken = await getJWT(token);
        await axios.post(baseApi + '/bulk/labels/pallet/create_from_ordernbr', {vendor_name: vendor_name, internal_po_number: internal_po_number}, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
    },

    getPalletDims: async function(type, company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/pallet_items?type="
        let res = await axios.get(url + type, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}})
        const zlib = require("zlib")
        let data = JSON.parse(zlib.inflateSync(Buffer.from(res.data, 'base64')));
        return data;
    },  

    getPalletCalculator: async function(type, company_id,token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/pallet_calculator?type="
        let res = await axios.get(url + type, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}})
        return res;
    },

    getProductionCalendar: async function(company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/v1/operations/getProductionSchedule"
        let res = await axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}})
        console.log(res)
        return res.data
    },

    uploadPalletFile: async function(pallets, company_id, token) {
        const userToken = await getJWT(token);
        let res = await axios.post(baseApi + '/bulk/labels/pallet/create_from_file', {data: pallets}, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
        return res.data;
    },

    getAcumaticaOrder: async function(ordernbr, company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + `/acumatica/order?ordernbr=${ordernbr}`
        console.log(url)
        let res = await axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}})
        console.log(res)
        return res.data
    },

    saveItemShorts: async function(body, company_id, token) {
        const userToken = await getJWT(token);
        let res = await axios.put(baseApi + '/bulk/orders/short', body, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
        return res.data;
    },

    getTransactions: async function(company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/transactions"
        let res = await axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}})
        console.log(res)
        return res.data
    },



    downloadBulkTransaction: async function(body, company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/transactions/bulk"
        let res = await axios.get(url, {params: body, headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}})
        return res;
    },

    getLots: async function(company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/lots"
        let res = await axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}})
        return res;
    },

    uploadLots: async function(body, company_id, token) {
        const userToken = await getJWT(token);
        await axios.post(baseApi + '/bulk/lots', body, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
    },

    getOrdersByLot: async function(lot_id, token, company_id) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/lots/orders?lot_id=" + lot_id
        let res = await axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}})
        return res;
    },

    getBulkInvoices: async function(company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/invoices";
        let res = await axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}})
        return res;
    },

    updateBulkInvoice: async function(body, company_id, token) {
        const userToken = await getJWT(token);
        let obj = {headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }}
        let res = await axios.put(`${baseApi}/bulk/invoices/order`, body, obj);
        return res;
    },

    sendRouteRequest: async function(body, token, company_id) {
        const userToken = await getJWT(token);
        let obj = {headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }}
        let res = await axios.post(`${baseApi}/bulk/orders/routing`, body, obj);
        return res;
    },
    downloadDropshipTransaction: async function(body, company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/dropship/transactions/dropship"
        let res = await axios.get(url, {params: body, headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}})
        return res;
    },

    getShippingDashboard: async function(warehouse) {
        let url = baseApi + "/v1/operations/ShippingDash?warehouse=" + warehouse;
        let res = await axios.get(url, {headers: {"company_id": "Operations"}})
        return res.data;
    },

    downloadBoxLabelFile: async function(fileName, company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/labels/download?key=" + fileName
        axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((result) => {
            let body = result.data;
            const a = document.createElement("a");
            a.download = fileName;
            a.target = "_blank"
            a.href = body;
            a.click();
        })
        .catch(function(err) {
            console.log(err);
        })
    },

    downloadPalletLabelFile: async function(fileName, company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/labels/pallet/download?key=" + fileName
        axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((result) => {
            let body = result.data;
            const a = document.createElement("a");
            a.download = fileName;
            a.target = "_blank"
            a.href = body;
            a.click();
        })
        .catch(function(err) {
            console.log(err);
        })
    },

    getCarriers: async function(company_id, token) {
        const userToken = await getJWT(token);
        let url = baseApi + "/bulk/carriers"
        let body = await axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}})
        return body.data
    },

    downloadData: async function(fileNameRaw, company_id, token) {
        const userToken = await getJWT(token);
        let fileName = fileNameRaw.slice(fileNameRaw.indexOf("/") + 1, -4);
        let url = baseApi + "/bulk/labels/data?filename=" + fileName;
        axios.get(url, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((result) => {
            let body = result.data;
            body.unshift({date:"date",gsin:"gsin",asin:"asin",units:"units",cartons:"cartons",po_number:"po_number"});            
            let out = body.map(obj => `${obj.date}, ${obj.gsin}, ${obj.asin}, ${obj.units}, ${obj.cartons}, ${obj.po_number}`).join('\r\n');
            const a = document.createElement("a");
            a.download = fileName + ".csv";
            a.target = "_blank"
            a.href = "data:attachment/csv," + out;
            a.click();
        })
        .catch(function(err) {
            console.log(err);
        })
    },

    createLabelsForOrder: async function(ordernbr, setordernbr, company_id, user, token) {
        const userToken = await getJWT(token);
        await axios.post(baseApi + '/bulk/labels/create_from_ordernbr', {ordernbr: ordernbr, user: user}, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
        setordernbr(null);
    },

    submitFile: async function(file, changeFile, company_id, user, token) {
        const userToken = await getJWT(token);
        let promise = formatFile(file);
        promise.then(function(result) {
            changeFile(null);
            axios.post(baseApi + '/bulk/labels/create', {"data": result, "user":user}, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}}).then((response) => {
                console.log("File Submitted");
            });
        });
    },

    createPalletLabels: async function(ordernbr, company_id, token) {
        const userToken = await getJWT(token);
        await axios.post(baseApi + '/bulk/labels/pallet/create_from_ordernbr', {ordernbr: ordernbr}, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
    },

    exportASNs: async function(company, token) {
        const userToken = await getJWT(token);
        let res = await axios.get(baseApi +"/bulk/shipment/shipment_bulk", {headers: {"company_id": company[0].toUpperCase() + company.substring(1), Authorization: `Bearer ${userToken}`}});
        return res;
    },

    getBulkInventory: async function(company, token) {
        const userToken = await getJWT(token);
        let res = await axios.get(baseApi +"/bulk/inventory", {headers: {"company_id": company[0].toUpperCase() + company.substring(1), Authorization: `Bearer ${userToken}`}});
        return res;
    },

    updateBulkShipment: async function(shipments, company_id, token) {
        const userToken = await getJWT(token);
        console.log(shipments)
        await axios.put(baseApi + '/bulk/shipment/shipment_bulk', {data: shipments}, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
    },

    getBulkOrdersTable: async function(company_id, params, token){
        const userToken = await getJWT(token);
        const url = `${baseApi}/bulk/orders/table`;
        try {
            let res_compressed = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params })
            console.log(res_compressed)
            const zlib = require("zlib")
            let res = JSON.parse(zlib.inflateSync(Buffer.from(res_compressed.data, 'base64')));
            console.log(res);
            let orders = res.filter(obj => obj.internal_po_number);
            if(!orders) return []

            return orders;
        } catch (err) {
            console.log(err);
            return null
        }
    },

    confirmShipment: async function(company_id, po, vendor, token) {
        const userToken = await getJWT(token);
        let body = {vendor_name: vendor, internal_po_number: po};
        await axios.post(baseApi + '/bulk/orders/shipment/confirm', body, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
    },

    getUploads: async function(company_id, params, token){
        const userToken = await getJWT(token);
        const url = `${baseApi}/bulk/orders/uploads`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params });
            res.data.forEach(upload => {
                upload['id'] = upload.upload_id
            })
            console.log(res.data)
            return res.data;
        } catch (err) {
            // console.log(err);
            return err;
        }
    },
    
    deleteUpload: async function(company_id, body, token) {
        const userToken = await getJWT(token);
        const url = `${baseApi}/bulk/uploads`;
        try {
            let res = await axios.delete(url, {data: body, headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }});
            if(res.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            console.log(err);
            return false;        
        }
    },

    sendAck: async function(body, token, company_id) {
        const userToken = await getJWT(token);
        let res = await axios.post(baseApi + '/bulk/orders/acknowledgement', body, {headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }});

    },

    getNewOrders: async function(vendor, company_id, token) {
        const userToken = await getJWT(token);
        let res = await axios.get(baseApi + `/bulk/orders/new?vendor_name=${vendor}`, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
        const zlib = require("zlib")
        let data = JSON.parse(zlib.inflateSync(Buffer.from(res.data, 'base64')));
        return data.orders;
    },

    sendASNs: async function(vendors, company_id, token) {
        const userToken = await getJWT(token);
        for(let vendor of vendors) {
            let body = {vendor_name: vendor, request_type: "shipping"};
            await axios.post(baseApi + '/dropship/vendor', body, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
        }
        return
    },

    forceOrderBuilt: async function(company_id, so, po, vendor, token) {
        let body = {acu_order_num: so, internal_po_number: po, vendor_name: vendor};
        const userToken = await getJWT(token);
        await axios.put(baseApi + '/bulk/orders/built', body, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
    },

    updateShipment: async function(shipment, vendor_name, internal_po_number, token, company_id) {
        let body = shipment;
        body.internal_po_number = internal_po_number;
        body.vendor_name = vendor_name;
        console.log(body);
        const userToken = await getJWT(token);
        try {
            await axios.put(baseApi + '/bulk/shipment/update', body, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
        } catch (err) {
            console.log(err);
            return null
        }
    },

    getOrderChangeRequestsByPO: async function(token, company_id, internal_po_number, vendor_name) {
        const userToken = await getJWT(token);
        try {
            let res = await axios.get(baseApi + `/bulk/orders/changeRequestsByPo?internal_po_number=${internal_po_number}&vendor_name=${vendor_name}`, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
            return res;
        } catch (err) {
            console.log(err);
            return null
        }
    },

    getOrderChangeRequests: async function(token, company_id) {
        const userToken = await getJWT(token);
        try {
            let res = await axios.get(baseApi + `/bulk/change_requests`, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
            return res;
        } catch (err) {
            console.log(err);
            return null
        }
    },

    acceptChanges: async function(body, company_id, token) {
        const userToken = await getJWT(token);
        await axios.put(baseApi + '/bulk/orders/accept_changes', body, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});

    },

    insertCarrier: async function(carrier, token, company_id) {
        const userToken = await getJWT(token);
        
        await axios.post(baseApi + '/bulk/carriers', carrier, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});

    },

    resendToAcumatica: async function(company_id, params, token) {
        const userToken = await getJWT(token);
        try {
            await axios.put(baseApi + '/bulk/orders/acumatica/resend', params, {headers: {"company_id": company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}`}});
        } catch (err) {
            console.log(err);
            return null
        }
    }

}

export default bulkHelpers;