import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import OrderQueryForm from './OrderQueryForm'
import OrderSearchTable from './OrderSearchTable';
import orderHelpers from '../../helpers/orders';
import vendorHelpers from '../../helpers/vendors';
import OrderDetails from './OrderDetails';

function OrderSearch(props) {

    const [refresh, setRefresh] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [vendor, setVendor] = useState(null);
    const [uploadId, setUploadId] = useState(null);
    const [pullsheetId, setPullsheetId] = useState(null);
    const [internalPoNumber, setInternalPoNumber] = useState(null);
    const [externalPoNumber, setExternalPoNumber] = useState(null);
    const [trackingNumber, setTrackingNumber] = useState(null);
    const [name, setName] = useState(null);
    const [sku, setSku] = useState(null);
    const [openDetails, setOpenDetail] = useState(null);
    const [orders, setOrders] = useState([]);
    const [detailsTab, setDetailsTab] = useState(null);
    const [direction, setDirection] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);
    const [acumaticaStatus, setAcumaticaStatus] = useState(null);
    const [trackingStatus, setTrackingStatus] = useState(null);
    const [warehouse, setWarehouse] = useState(null);

    useEffect(() => {
        const getVendors = async() => {
            let vendorList = await vendorHelpers.getVendors(props.company, props.token)
            setVendorList(vendorList.vendors)
        }
        getVendors();
    }, [refresh])

    const handleChange = (event) => {
        let func = `set${event.target.name[0].toUpperCase() + event.target.name.substring(1)}`
        eval(func)(event.target.value)
    }

    const handleDateChange = (date, field) => {
        let func = setStartDate;
        if(field === "endDate") func = setEndDate
        if(date !== null){
            func({formatted: date.format("YYYY-MM-DD"), full: date})
        } else {
            func(date)

        }
    }

    const formatOrders = async(orders) => {
        let pos = {};
        for(let order of orders) {
            let po = order.internal_po_number;
            if(!(po in pos)) {
                pos[po] = {
                    acu_order_num: order.acu_order_num,
                    address1: order.address1,
                    address2: order.address2,
                    city: order.city,
                    external_po_number: order.external_po_number,
                    internal_po_number: po,
                    name: order.name,
                    order_date: order.order_date,
                    order_status: order.order_status,
                    state: order.state,
                    upload_date: order.upload_date,
                    vendor_name: order.vendor_name,
                    zip_code: order.zip_code,
                    shipments: {},
                    items: []
                }
            }
            let tn = order.tracking_number;
            if(!(order.tracking_number in pos[po].shipments)) {
                pos[po].shipments[tn] = {
                    acu_ship_num: order.acu_ship_num,
                    acumatica_status: order.acumatica_status,
                    carrier: order.carrier,
                    method: order.method,
                    date: order.upload_date,
                    truck_number: order.truck_number,
                    tracking_number: tn,
                    tracking_status: order.tracking_status,
                    tote_number: order.tote_number,
                    vendor_status: order.vendor_status,
                    warehouse: order.warehouse,
                    total_on_shipment: order.total_on_shipment,
                    pullsheet_id: order.pullsheet_id,
                    items: {}
                }
            }
            let sku = order.internal_sku;
            if(!(sku in pos[po].shipments[tn].items)) {
                pos[po].shipments[tn].items[sku] = {
                    external_sku: order.external_sku,
                    internal_price: order.internal_price,
                    external_price: order.external_price,
                    internal_sku: sku,
                    quantity: 0
                }
            }
            pos[po].items.push({order_id:order.order_id, description:order.description, internal_sku: sku})
            pos[po].shipments[tn].items[sku].quantity += order.quantity;
        }
        let out = [];
        let id = 1;
        for(let po in pos) {
            let shipments = [];
            for(let tn in pos[po].shipments) {
                let items = [];
                for(let sku in pos[po].shipments[tn].items) {
                    items.push(pos[po].shipments[tn].items[sku]);
                }
                pos[po].shipments[tn].items = items;
                shipments.push(pos[po].shipments[tn]);
            }
            pos[po].shipments = shipments;
            pos[po].id = id;
            out.push(pos[po]);
            id++;
        }
        return out;
    }

    const getOrders = async () => {
        props.setLoading(true);
        let params = {
            start_date: startDate !== null ? startDate.formatted : null, 
            end_date: endDate !== null ? endDate.formatted : null, 
            vendor_name: vendor, 
            upload_id: uploadId, 
            pullsheet_id: pullsheetId, 
            internal_po_number: internalPoNumber, 
            external_po_number: externalPoNumber,
            tracking_number: trackingNumber, 
            name: name,
            sku: sku,
            order_status: orderStatus !== 0 ? orderStatus : null,
            tracking_status: trackingStatus !== 0 ? trackingStatus : null,
            acumatica_status: acumaticaStatus !== 0 ? acumaticaStatus : null,
            warehouse: warehouse !== "" ? warehouse : null,
            limit: 1000,
            order_by: "order_id",
            on_pullsheet: trackingStatus === 200 ? true : null,
            order_valid: trackingStatus !== 0 ? true : null
        }
        console.log(params);
        try {
            let res = await orderHelpers.getOrders(props.company, params, props.token);
            let orders = await formatOrders(res);
            setOrders(orders);
        }
        catch(err) {
            console.log(err);
        }
        
        props.setLoading(false);
    }

    const setOpenDetails = async(order = {}) => {
        if(Object.keys(order)?.length === 0 ) {
            setOpenDetail(order);
            setDetailsTab(0);
        }
        else {
            setOpenDetail(order.row);
            setDetailsTab(0)
        }
    }

    const setTab = async(e) => {
        console.log(e);
        let tab = Number(e.target.name);
        if(tab > detailsTab) setDirection("left");
        else setDirection("right")
        setTab(tab);
    }

    return(
        <Box className={props.isMobile ? "mobile-top" : "desktop-top"} sx = {{display: 'flex', flexDirection: 'column'}}>
            <OrderDetails {...props} direction={direction} setTab={setTab} tab={detailsTab} order={openDetails} setOpenDetails={setOpenDetails} />
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
                <h1>Order Search</h1>
                <OrderQueryForm
                    isMobile={props.isMobile}
                    vendors={vendorList} 
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    getOrders={getOrders}
                    startDate={startDate?.full}
                    endDate={endDate?.full}
                />
                <OrderSearchTable 
                    isMobile={props.isMobile}
                    orders={orders}
                    setOpenDetails={setOpenDetails}
                />
            </Box>
        </Box>
    )
}

export default OrderSearch;