import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {lighten } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const columns = [ 
    {
        field: 'lot_id',
        headerName: "ID",
        flex: 1,
        editable: false,
        minWidth: 50,
        maxWidth: 150
    },
    {
        field: 'lot_number',
        headerName: 'Lot Number',
        flex: 1,
        editable: false,
        minWidth: 150,
    }, 
    {
        field: "manufacturer",
        headerName: "Manufacturer",
        flex: 1,
        editable: false,
        minWidth: 150,
    },
    {
        field: 'production_date',
        headerName: 'Production Date',
        flex: 1,
        editable: false,
        minWidth: 250,
        renderCell: (params) => {
            let date = params.row.production_date.replace("T", " ").replace("Z", " ").replace(".000", "");
            return(
                <Tooltip title={date}>
                    <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{date}</Box>
                </Tooltip>
            )
        },
    },
    {
        field: 'expiration_date',
        headerName: "Expiration Date",
        sortable: false,
        editable: false,
        minWidth: 250,                
        renderCell: (params) => {
            let date = params.row.expiration_date.replace("T", " ").replace("Z", " ").replace(".000", "");
            return(
                <Tooltip title={date}>
                    <Box style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{date}</Box>
                </Tooltip>
            )
        },
    }

]


function LotsTable(props) {

    return(
        <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"} style={{width:"100%"}}>
            <DataGrid
                style={{width:"70%"}}
                rows={props.lots}
                className={props.isMobile ? "mobile-table" : "desktop-table"}
                columns={columns}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {false}
                getRowClassName={(params) => params.row.class}
                sortModel = {[{field:'id', sort: 'desc'}]}
                onRowClick = {props.setOpenDetails}
            />
        </Box>   
    )


}

export default LotsTable;