import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

function OrderQueryForm(props) {

    const order_statuses = [
        {value: 0, name: ""},
        {value:100, name:"order_created"},
        {value:101, name:"order_pulled"},
        {value:102, name:"order_built"},
        {value:103, name:"order_staged"},
        {value:104, name:"order_shipped"},
        {value:199, name:"order_cancelled"}
    ];

    const tracking_statuses = [
        {value: 0, name: ""},
        {value:200, name:"tracking_created"},
        {value:201, name:"tracking_confirmed"},
        {value:299, name:"tracking_reshipped"}
    ];

    const acumatica_statuses = [
        {value: 0, name: ""},
        {value:400, name:"acumatica_processing"},
        {value:401, name:"acumatica_order_created"},
        {value:402, name:"acumatica_shipment_created"},
        {value:-402, name:"acumatica_shipment_failed"},
        {value:-401, name:"acumatica_order_failed"},
    ];

    const warehouses = [
        {value: "Kentucky", name: "Kentucky"},
        {value: "Pennsylvania", name: "Pennsylvania"}
    ]

    return(
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} sx={{display: 'flex', flexDirection: 'column'}}>
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} sx={{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mb: '10px'}}>
            <FormControl sx={{ mr: '15px', minWidth: 120 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="Start Date"
                        name='orderStartDate'
                        onChange={(event) => props.handleDateChange(event, 'startDate')}
                        value={props.startDate ? props.startDate : null}
                        renderInput={(params) => <TextField {...params} />}
                        clearable={true}
                    />
                </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ mr: '10px', minWidth: 150 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="End Date"
                        name='orderEndDate'
                        onChange={(event) => props.handleDateChange(event, 'endDate')}
                        value={props.endDate ? props.endDate : null}
                        renderInput={(params) => <TextField {...params} />}
                        clearable={true}
                    />
                </LocalizationProvider>
            </FormControl>
            <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="vendorInput">Vendor</InputLabel>
                    <Select
                        name='vendor'   
                        label="Vendor"        
                        onChange={props.handleChange}
                        defaultValue=''
                    >
                        {/* <MenuItem value=''>None</MenuItem> */}
                        {props.vendors ? props.vendors.map((vendor, index) => 
                        <MenuItem key={index} value={vendor.toLowerCase().replace(/\s/g , "_")}>{vendor}</MenuItem>
                        ) : null}
                    </Select>
            </FormControl>
        </Box>
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} sx={{flexDirection: 'row', mb: '10px'}}>
            <TextField 
                name='uploadId'
                label='Upload ID'
                onChange = {props.handleChange}
                sx = {{mr: '10px'}}
            />
            <TextField 
                name='pullsheetID'
                label='Pullsheet ID'
                onChange = {props.handleChange}
                sx = {{mr: '10px'}}
            />
            <TextField 
                    name='internalPoNumber'
                    label='Internal PO'
                    onChange = {props.handleChange}
                    sx = {{mr: '10px'}}
            />
            <TextField 
                    name='externalPoNumber'
                    label='External PO'
                    onChange = {props.handleChange}
                    sx = {{mr: '10px'}}
            />
        </Box>
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} sx={{flexDirection: 'row', mb: '10px'}}>
            <TextField 
                name='trackingNumber'
                label='Tracking Number'
                onChange = {props.handleChange}
                sx = {{mr: '10px'}}
            />
            <TextField 
                name='name'
                label='Name'
                onChange = {props.handleChange}
                sx = {{mr: '10px'}}
            />
            <TextField 
                name='sku'
                label='SKU'
                onChange = {props.handleChange}
                sx = {{mr: '10px'}}
            />
        </Box>
        <Box className={props.isMobile ? "mobile-box" : "desktop-box"} sx={{flexDirection: 'row', mb: '10px'}}>
            <FormControl sx={{ minWidth: 150, mr:"20px" }}>
                <InputLabel id="warehouseInput">Warehouse</InputLabel>
                <Select
                    name='warehouse'   
                    label="Warehouse"        
                    onChange={props.handleChange}
                    defaultValue=''
                >
                    {warehouses.map((warehouse, index) => {
                        let name = warehouse.name;
                        
                        return(
                            <MenuItem key={index} value={warehouse.value}>{name}</MenuItem>
                        )
                    }
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 150, mr:"20px" }}>
                <InputLabel id="orderStatusInput">Order Status</InputLabel>
                <Select
                    name='order_status'   
                    label="Order Status"        
                    onChange={props.handleChange}
                    defaultValue=''
                >
                    {order_statuses.map((status, index) => {
                        let name = status.name;
                        if(name !== "") {
                            let first = status.name[0].toUpperCase() + status.name.substring(1, status.name.indexOf("_"));
                            let last = status.name.substring(status.name.indexOf("_") + 1);
                            name = first + " " + last[0].toUpperCase() + last.substring(1)
                        }
                        return(
                            <MenuItem key={index} value={status.value}>{name}</MenuItem>
                        )
                    }
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 200, mr:"20px" }}>
                <InputLabel id="acumaticaStatusInput">Acumatica Status</InputLabel>
                <Select
                    name='acumaticaStatus'   
                    label="Acumatica Status"        
                    onChange={props.handleChange}
                    defaultValue=''
                >
                    {acumatica_statuses.map((status, index) => {
                        let name = "";
                        if(status.value) {
                            let temp = status.name;
                            while(temp.includes("_")) {
                                name += temp[0].toUpperCase() + temp.substring(1, temp.indexOf("_")) + " ";
                                temp = temp.substring(temp.indexOf("_") + 1);
                            }
                            name = name + temp[0].toUpperCase() + temp.substring(1);
                        }
                        return(
                            <MenuItem key={index} value={status.value}>{name}</MenuItem>
                        )
                    }
                    )}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 175, mr:"20px" }}>
                <InputLabel id="trackingStatusInput">Tracking Status</InputLabel>
                <Select
                    name='trackingStatus'   
                    label="Tracking Status"        
                    onChange={props.handleChange}
                    defaultValue=''
                >
                    {tracking_statuses.map((status, index) => {
                        let name = status.name;
                        if(name !== "") {
                            let first = status.name[0].toUpperCase() + status.name.substring(1, status.name.indexOf("_"));
                            let last = status.name.substring(status.name.indexOf("_") + 1);
                            name = first + " " + last[0].toUpperCase() + last.substring(1)
                        }
                        return(
                            <MenuItem key={index} value={status.value}>{name}</MenuItem>
                        )
                    }
                    )}
                </Select>
            </FormControl>
        </Box>
        <Button variant='contained' onClick={props.getOrders} color="success" sx={{mt: '10px', width:"20%"}}>Search</Button>
    </Box>
    )
}

export default OrderQueryForm;