import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LotsTable from './LotsTable'
import Button from '@mui/material/Button';
import { CSVReader } from 'react-papaparse';
import bulkHelpers from '../../helpers/bulk'
import LotDetails from './LotDetails';

const buttonRef = React.createRef();

function Lots(props) {

    const HEADERS = ["internal_po_number", "vendor_name", "internal_sku", "lot_number", "manufacturer", "production_date", "expiration_date"]


    const [lots, setLots] = useState([])
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [uploads, setUploads] = useState(null);
    const [success, setSuccess] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [openDetails, setOpenDetail] = useState(null);
    const [detailsTab, setDetailsTab] = useState(null);


    const setOpenDetails = async(order = {}) => {
        if(Object.keys(order)?.length === 0 ) {
            setOpenDetail(order);
            setDetailsTab(0);
        }
        else {
            setOpenDetail(order.row);
            setDetailsTab(0)
        }
    }

    useEffect(() => {
        const getLots = async() => {
            let res = await bulkHelpers.getLots(props.company + "_bulk", props.token)
            let out = res.data.map((obj, index) => {return {...obj, id:index}});
            console.log(out)
            setLots(out)
        }
        getLots();
    }, [refresh])

    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    };

    const formatUpload = (data) => {
        try {
            const dataArray = [];
            data.forEach(row =>{
                let d = row.data;
                if(d.production_date) {
                    d.production_date = new Date(d.production_date).toISOString();
                }
                if(d.expiration_date) {
                    d.expiration_date = new Date(d.expiration_date).toISOString();
                }
                console.log(d);
                dataArray.push(d)
            })
            return dataArray;
        }
        catch(err) {
            console.log(err);
            window.alert(err);
            return false;
        }

    }

    const handleOnFileLoad = (data, file) => {
        console.log('---------------------------');
        setFile(file);
        setSuccess(false);
        const up = formatUpload(data);
        if(!up) {
            setFile(null);
            return;
        }
        setUploads(up);
        console.log('---------------------------');
    };
    
    const handleOnError = (err, file, inputElem, reason) => {
        console.log('---------------------------');
        console.log(err);
        console.log('---------------------------');
        setError(`Error Uploading Order File: ${file}`)
    };

    
    const uploadFile = async () => {
        props.setLoading(true);
        setError("")
        setSuccess(false)
        props.toast.promise(uploadHelper(), {
            success: "Lots Uploaded!",
            error: "Failed to Upload Lots"
        })
        // if(upload === true) {
        //     setSuccess(true);
        //     handleRemoveFile()
        //     setTimeout(() => {
        //         // getUploads()
        //     }, 5000)
        // } 
        // else {
        //     setError(`Error Uploading Order File: ${upload}`);
        // }
        props.setLoading(false);
    }

    const uploadHelper = async() => {
        await bulkHelpers.uploadLots({data: uploads}, props.company + "_bulk", props.token)
    }

    const handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e);
            setFile(null);
            setUploads(null);
        }
    };

    const downloadTemplate = async() => {
        let csv = HEADERS;
        let blob = new Blob([csv], {type: "text/csv"})
        let uri = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.setAttribute("href", uri);
        a.setAttribute("download", "LOT_NUMBERS_TEMPLATE.csv");
        document.body.appendChild(a);
        a.click();
    }

    return(
        <Box display="flex" flexDirection="column" className={props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className={props.isMobile ? "mobile-box" : "desktop-box"}>
            <LotDetails {...props} lot={openDetails} setOpenDetails={setOpenDetails} />

                <h1>Lots</h1> 
                <Button onClick={() => downloadTemplate()}>Get Template</Button>

                <h3>This process will only work if every individual SKU on the order has <u> ONLY ONE</u> lot number! </h3>
                <h5>If you have one SKU on an order that has multiple lot numbers, email the breakdown of which lot numbers went with which Box Label to Matt.</h5>
                <CSVReader
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noClick
                        noDrag
                        config={{
                            header: true,
                            skipEmptyLines: true
                        }}
                        style={{marginTop:"20px", marginBottom:"20px"}}
                        >
                        {({ file_obj }) => (
                    <aside style={{marginTop:"20px", marginBottom:"20px"}}>
                        <Button variant='contained' onClick={handleOpenDialog} className={`${props.company} ${props.warehouse} secondary`}
                        // sx={{mr: '10px', mb: '10px'}}
                        >Browse file</Button>
                        <div>
                        {file_obj && file_obj.name}
                        </div>
                        {file !== null ? 
                        <Box 
                        // sx={{flexDirection: 'row', mt: '10px'}}
                        >
                            <Button variant='contained' onClick={uploadFile} className={`${props.company} ${props.warehouse} secondary`}
                            // sx={{mr: '10px'}}
                            >Upload</Button>
                            <Button variant='contained' sx={{ml:"30px"}} onClick={handleRemoveFile} className={`${props.company} ${props.warehouse} tertiary`}>Remove</Button>
                        </Box>
                        : null}
                    </aside>
                    )}
                </CSVReader>
                <Button onClick={()=>setRefresh(!refresh)} variant="contained">Refresh</Button>
                <LotsTable {...props} lots={lots} setOpenDetails={setOpenDetails}/>
            </Box>
        </Box>
    )
}

export default Lots;