import React, {useRef, useState, useEffect} from 'react';
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box';
import bulkHelpers from '../../helpers/bulk'
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';


const columns = [ 
    {
        field: 'internal_po_number',
        headerName: "PO Number",
        flex: 1,
        editable: false,
        minWidth: 50,
        maxWidth: 150
    },
    {
        field: 'internal_sku',
        headerName: 'SKU',
        flex: 1,
        editable: false,
        minWidth: 150,
    }, 
    {
        field: "quantity",
        headerName: "Quantity",
        flex: 1,
        editable: false,
        minWidth: 150,
    }
]

function LotDetails(props) { 

    const lot = props.lot || {};
    const [orders, setOrders] = useState([]);
    const [status, setStatus] = useState({});
    const [error, setError] = useState("");

    useEffect(() => {
        const getOrders = async() => {
            if(!lot.lot_id) return;
            let res = await bulkHelpers.getOrdersByLot(lot.lot_id, props.token, props.company + "_bulk")
            setOrders(res.data.map((obj, index) => {return({...obj, id: index})}));
        }
        getOrders();
    }, [lot])

    return (
        <Drawer open={Object.keys(lot).length > 0} onClose={props.setOpenDetails} anchor="bottom">
            <Box className={props.isMobile ? "mobile-table-box" : "desktop-table-box"}  style={{height: "600px", width:"100%"}}>
                <DataGrid
                    style={{width:"70%", margin:"auto"}}
                    rows={orders}
                    className={props.isMobile ? "mobile-table" : "desktop-table"}
                    columns={columns}
                    rowsPerPageOption = {[25, 50, 100]}
                    checkboxSelection = {false}
                    getRowClassName={(params) => params.row.class}
                    sortModel = {[{field:'id', sort: 'desc'}]}
                />
            </Box>   
        </Drawer>
    )

}

export default LotDetails;


